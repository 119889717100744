'use-client';

import useScreen from 'hooks/useScreen';
import PropTypes from 'prop-types';

const TabletAndDesktopOnlyLegacy = ({ children, fallback = null }) => {
  const { isTabletOrDesktop } = useScreen();

  return isTabletOrDesktop ? children : fallback;
};

TabletAndDesktopOnlyLegacy.propTypes = {
  children: PropTypes.node,
};

export default TabletAndDesktopOnlyLegacy;
